import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GiWhistle } from 'react-icons/gi';
import { parseData, parseFeatured } from '../helpers/news';
import { getCalendar, parseCalendar } from '../helpers/matches';
import { RenderHighlight } from '../components/cards';
import { Highlights } from '../components/news';
import HeroBanner from '../components/hero-banner';
import Button from '../components/button';
import { renderGalleries } from '../components/galleries';
import SEO from '../components/seo';
import PlayersWidget from '../components/players-widget';
import SoundCloud from '../components/soundcloud';
import withBanners from '../data/banners';
import ConditionalRenderer from '../components/conditionalRenderer';
import HighlightedWidget, {
  withHighlightedNews,
  withLatestNews,
} from '../components/news-widget';
import SeeMore from '../components/see-more';
import NextMatchWidget from '../components/next-match-widget';
import FixedBanners from '../components/fixed-banners';
import withFixedBanners from '../data/fixed-banners';
import withSponsoredBanners from '../data/sponsored-banners';
import MatchesWidget from '../components/matches-widget';
import Youtube from '../components/youtube';
import Twitter from '../components/twitter';
import MatchBar from '../components/match-bar';
import CallToAction from '../components/call-to-action';
// import SoundCloud from '../components/soundcloud';
// import MembersCounterModal from '../components/MembersCounterModal';

const Section = styled.section`
  width: 100%;
  margin: ${({ isFullwidth }) => (isFullwidth ? '4rem 0' : '0 auto 4rem')};
  max-width: ${({ theme }) => theme.dimensions.desktop.main};

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    margin: 0.5rem 1rem;

    & > h2 {
      font-family: 'Bebas Neue';
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.primary_dark};
      margin: 0;
    }

    & > small {
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 1rem;
      opacity: 0.7;
    }
  }
`;
const PlayersSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary_dark};
  padding: 2rem 0 4rem;

  .players__container {
    max-width: ${({ theme }) => theme.dimensions.desktop.main};
    width: 100%;
  }

  .section__header {
    color: ${({ theme }) => theme.colors.white};

    & > h2 {
      font-family: 'Bebas Neue';
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0.5rem 1rem;
    }
  }
`;

const FloatingButton = styled.div`
  height: 48px;
  position: fixed;
  z-index: 10000;
  color: black;
  bottom: 3rem;
  right: 12rem;
  margin: 13px;

  @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    right: unset;
    left: 1rem;
  }

  a {
    display: block;
    background-color: #8f00ff;
    transition: background-color 0.3s ease-in-out;
    :hover,
    :focus {
      background-color: #6400b4;
    }
    height: 100%;
    padding: 0.92857rem 1.57143rem;
    @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      padding: 1rem;
    }
    border-radius: 999rem;
  }
  svg {
    -webkit-transform: rotate(70deg) scaleX(-1.2);
    transform: rotate(70deg) scaleX(-1.2);
    margin-right: 0.2rem;
  }
  span {
    @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      /* display: none; */
    }
    font-weight: bold;
    text-transform: initial;
  }
`;

const Banner = withBanners(HeroBanner);
const Banners = withFixedBanners(FixedBanners);
const SponsoredBanners = withSponsoredBanners(FixedBanners);

const HomeBanner = styled.section`
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    margin-top: -140px;
  }
`;

const NextMatchSection = styled.section`
  margin-bottom: 4rem;
`;

const MatchBarSection = styled(Section)`
  margin: auto;
  h2 {
    all: unset;
    display: block;
    visibility: hidden;
    height: 0;
  }
`;

const Span = styled.span`
  @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    display: none;
  }
`;

export default function Index(props) {
  // const [modalOpen, setModalOpen] = useState(false);
  // const [counter, setCounter] = useState(null);
  const { data } = props;
  const {
    groundApi: { nextMatch },
    wpgraphql: {
      latestGalleries: { nodes: galleries },
      featuredMatches: { nodes: featuredMatches },
      generalConfig: {
        featuredPosts: { main, second, third },
      },
    },
    site: {
      siteMetadata: { groundAPI, reportEndpoint },
    },
  } = data;
  const handleScroll = () => {
    if (typeof document !== 'undefined') {
      const header = document.getElementsByTagName('header');

      if (typeof window !== 'undefined' && window.scrollY > 50) {
        header[0].classList.remove('transparent');
      } else {
        header[0].classList.add('transparent');
      }
    }
  };

  const [calendar, setCalendar] = useState({
    matches: [],
    previous: [],
    next: null,
    following: [],
    featuredMatches: [],
  });

  useEffect(() => {
    handleScroll();

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    const fetchCalendar = async () => {
      const matches = await getCalendar(groundAPI, featuredMatches);
      const parsed = parseCalendar(matches);
      setCalendar({ ...parsed, matches });
    };
    fetchCalendar();
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
      if (typeof document !== 'undefined') {
        const header = document.getElementsByTagName('header');
        header[0].classList.remove('transparent');
      }
    };
  }, []);
  return (
    <>
      <SEO title="Página Principal" />
      <HomeBanner>
        <Banner />
      </HomeBanner>
      {/* <MatchBarSection isFullwidth>
        <h2 aria-hidden="false">Jogos com check-in aberto</h2>
        <MatchBar matches={calendar.featuredMatches} />
      </MatchBarSection> */}
      <Section>
        <Highlights>
          <li className="highlight__main">
            <RenderHighlight item={parseFeatured(main)} />
          </li>
          <li className="highlight__2">
            <RenderHighlight item={parseFeatured(second)} />
          </li>
          <li className="highlight__3">
            <RenderHighlight item={parseFeatured(third)} />
          </li>
        </Highlights>
      </Section>
      <Section>
        <Banners />
      </Section>
      <Section>
        <div className="section__header">
          <h2>Destaques</h2>
          <SeeMore to="/n" />
        </div>
        <HighlightedWidget HOC={withHighlightedNews} />
      </Section>
      <Section>
        <div className="section__header">
          <h2>Últimas notícias</h2>
          <SeeMore to="/n" />
        </div>
        <HighlightedWidget HOC={withLatestNews} />
      </Section>
      <Section>
        <SponsoredBanners />
      </Section>
      <ConditionalRenderer
        condition={calendar.next}
        renderOnSuccess={() => (
          <NextMatchSection>
            <NextMatchWidget match={calendar.next} />
          </NextMatchSection>
        )}
      />
      {/* <Section>
        <div className="section__header">
          <h2>Campeonatos</h2>
        </div>
        <MatchesWidget />
      </Section> */}
      <Section>
        <div className="section__header">
          <h2>Galerias</h2>
          <SeeMore to="/galerias" />
        </div>
        {renderGalleries(galleries)}
      </Section>
      <Youtube />
      <Section />
      <Section>
        <div className="section__header">
          <h2>Twitter</h2>
        </div>
        <Twitter />
      </Section>
      {/* <Section isFullwidth>
        <div className="section__header">
          <h2>SoundCloud Colorado</h2>
        </div> */}
      <SoundCloud />
      {/* </Section> */}
      <PlayersSection>
        <div className="players__container">
          <PlayersWidget />
        </div>
      </PlayersSection>
      <ConditionalRenderer
        condition={reportEndpoint}
        renderOnSuccess={() => (
          <FloatingButton>
            <Button
              as="a"
              href={reportEndpoint}
              rel="external nofollow"
              target="_blank"
              aria-label="Canal de denúncias - Abre uma nova aba"
            >
              <GiWhistle />
              <Span>Canal de Denúncias</Span>
            </Button>
          </FloatingButton>
        )}
      />
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        groundAPI
        reportEndpoint
      }
    }
    wpgraphql {
      latestGalleries {
        nodes {
          date
          title
          slug
          id
          galleryData {
            imagens {
              imagem {
                sourceUrl
              }
            }
          }
        }
      }
      featuredMatches {
        nodes {
          servicoDeJogo {
            checkin {
              enabled
              startDate
              startTime
              endDate
              endTime
              link
            }
            tickets {
              enabled
              startDate
              startTime
              endDate
              endTime
              link
            }
            matchId
            showButton
            matchPageLink
          }
          slug
        }
      }
      generalConfig(id: "featured_news", idType: SLUG) {
        featuredPosts {
          main {
            linkType
            link
            hideTitle
            altTitle
            post {
              ... on WPGraphQL_Post {
                id
                title
                excerpt
                postData {
                  highlight
                  redirectLink
                  exclusive
                }
                slug
                date
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                tags {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
            image {
              sourceUrl
            }
          }
          second {
            linkType
            link
            hideTitle
            altTitle
            post {
              ... on WPGraphQL_Post {
                id
                title
                excerpt
                postData {
                  highlight
                  redirectLink
                  exclusive
                }
                slug
                date
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                tags {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
            image {
              sourceUrl
            }
          }
          third {
            linkType
            link
            hideTitle
            altTitle
            post {
              ... on WPGraphQL_Post {
                id
                title
                excerpt
                postData {
                  highlight
                  redirectLink
                  exclusive
                }
                slug
                date
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                tags {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
            image {
              sourceUrl
            }
          }
        }
      }
    }
    groundApi {
      nextMatch {
        championship
        date
        dateText
        time
        timeText
        stadium
        homeTeam
        homeTeamName
        homeImg
        visitorTeam
        visitorTeamName
        visitorImg
        gameId
      }
    }
  }
`;
